.dashboardDayWiseCustomSelectContainer {
  display: inline-block;
  min-width: 170px;
  min-height: 40px;
  position: relative;
}

.dashboardDayWiseCustomSelectContainer svg {
  height: 15px !important;
  width: 15px !important;
  margin-bottom: 0 !important;
}

.dashboardDayWiseSelectedText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 10px;
  background: rgba(224, 231, 255, 0.2);
  border: 1px solid #e0e7ff;
  border-radius: 5px;
  font-size: 14px;
}

.dashboardDayWiseSelectedText svg {
  font-size: 20px;
}

.dashboardDayWiseSelectedText.active::after {
  top: 8px;
  border-color: transparent transparent #fff transparent;
}

.dashboardDayWiseSelectOptions {
  position: absolute;
  width: 100%;
  z-index: 1;
  max-height: 250px;
  overflow-y: auto;
  font-size: 0.9em;
  margin: 0;
  padding: 0;
}

.dashboardDayWiseCustomSelectOption {
  list-style-type: none;
  padding: 6px 20px;
  background: #e0e7ff;
  cursor: pointer;
}

.dashboardDayWiseCustomSelectOption:hover {
  background-color: #2c62ec;
  color: #ffffff;
}

.dashboardDayWiseCustomOptionSelected {
  background: #2c62ec;
  color: #ffffff;
}
