.customSelectContainer {
  display: inline-block;
  min-width: 122px;
  min-height: 40px;
  position: relative;
}

.customSelectContainer svg {
  height: 15px !important;
  width: 15px !important;
  margin-bottom: 0 !important;
}

.selectedText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 10px;
  background: rgba(224, 231, 255, 0.2);
  border: 1px solid #e0e7ff;
  border-radius: 5px;
  font-size: 14px;
  width: 120px;
}

.selectedText svg {
  font-size: 20px;
}

.selectedText.active::after {
  top: 8px;
  border-color: transparent transparent #fff transparent;
}

ul {
  margin: 0;
  padding: 0;
}

.selectOptions {
  position: absolute;
  width: 100%;
  z-index: 1;
  max-height: 250px;
  overflow-y: auto;
}

.customSelectOption {
  list-style-type: none;
  padding: 6px 20px;
  background: #b2c6cc;
  cursor: pointer;
}

.customSelectOption:hover,
.customOptionSelected {
  background-color: #004357;
  color: #fff;
}

.previewButton {
  margin-left: 8px;
  cursor: pointer;
}

@media (min-width: 1800px) {
  .selectedText {
    width: 230px;
  }
}
