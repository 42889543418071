.drop-file-input {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  /* border: 1px dashed #6ac5ab; */
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.drop-file-input input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.drop-file-input:hover,
.drop-file-input.dragover {
  opacity: 0.6;
}

.drop-file-input__label {
  text-align: center;
  padding: 10px;
}
.drop-file-input__label_small {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.drop-file-input__label img {
  width: 50px;
}
.drop-file-input__label_small img {
  width: 40px;
}

.drop-file-input_subtitle {
  opacity: 0.4;
  margin-top: 0;
  margin-bottom: 3rem;
}

.drop-file-input_select {
  color: #6ac5ab;
  display: inline;
}
