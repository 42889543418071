.portfolioOverviewContainer {
  margin-bottom: 3em;
}
.overviewWrapper {
  display: flex;
}
.pieChartWrapper {
  display: flex;
  border: 1px solid rgba(150, 150, 150, 0.11);
  padding: 0.8em;
  box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px,
    rgb(0 0 0 / 30%) 0px 3px 7px -3px;
  border-radius: 10px;
  margin-right: 3em;
}
.labelsWrapper {
  margin-right: 1em;
  text-transform: capitalize;
}
.pieChartContainer {
  height: 140px;
  width: 140px;
}

.font-medium {
  font-size: 14px;
}
.pie-label::before {
  content: '\00b7';
  color: #bb7065;
  font-size: 95px;
  line-height: 8px;
  vertical-align: -26px;
}
.bucket-2::before {
  color: #8a0343;
}
.bucket-3::before {
  color: #d44638;
}
.bucket-4::before {
  color: #b23327;
}

.balance-1::before {
  color: #8c34ea;
}
.balance-2::before {
  color: #d325cc;
}
.balance-3::before {
  color: rgba(44, 98, 236, 0.7);
}
