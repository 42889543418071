.layoutflow {
  flex-grow: 1;
  position: relative;
  height: 100vh;
}

.layoutflow .graphDisplayControls {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
  font-size: 12px;
}

.layoutflow .graphDisplayControls button:first-child {
  margin-right: 10px;
}

.layoutflow .graphDisplayControls svg {
  font-size: 20px;
}

.react-flow__panel.right {
  display: none !important;
}

.react-flow__panel.left {
  top: 0 !important;
}

.react-flow__controls {
  box-shadow: none !important;
}

.react-flow__node {
  border: 1px solid rgb(234, 234, 234);
  box-shadow: rgb(0 0 0 / 4%) 6px 6px 14px;
  border-radius: 8px;
}

.react-flow__node.circle,
.react-flow__node.decisioncircle {
  border-radius: 50%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  /* background: #d9d9d9; */
  text-transform: capitalize;
  border: 1px solid #000;
}

.react-flow__node.decisioncircle {
  height: 3px;
  background: #fff;
}

.react-flow__node-default.selectable.selected {
  box-shadow: unset;
}

.react-flow__handle-bottom {
  opacity: 0;
}
