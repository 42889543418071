.channelBoxWrapper {
  padding: 1rem;
  min-width: 100px;
  box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px,
    rgb(0 0 0 / 30%) 0px 3px 7px -3px;
  min-height: 65px;
  border-radius: 18px;
  cursor: pointer;
}

.channelItemContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkboxWrapper input[type='checkbox'] {
  -webkit-appearance: none;
  appearance: none;
  width: 1.5em;
  height: 1.5em;
  border-radius: 0.15em;
  margin-right: 0.5em;
  background: #e0e7ff;
  outline: none;
  cursor: pointer;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

input.checked {
  background-color: #007a7e;
  position: relative;
}

input.checked::before {
  position: absolute;
  left: -1px;
  top: 46%;
  height: 30%;
  width: 2px;
  background-color: #004357;
  content: '';
  transform: translateX(10px) rotate(-45deg);
  transform-origin: left bottom;
}

input.checked::after {
  position: absolute;
  left: 0px;
  bottom: 4px;
  height: 2px;
  width: 57%;
  background-color: #004357;
  content: '';
  transform: translateX(10px) rotate(-54deg);
  transform-origin: left bottom;
}

.checkboxWrapper input[type='checkbox']:disabled {
  border-color: #c0c0c0;
  background-color: #c0c0c0;
}

.checkboxWrapper input[type='checkbox']:disabled + span {
  color: #c0c0c0;
}

.channelBoxSelected {
  background: #004357;
  color: #fff;
}
