.integration_drop-input {
  position: relative;
  width: 98%;
  height: 98%;
  border: 2px dashed #e0e7ff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.integration_drop-input input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.integration_drop-input:hover,
.integration_drop-input.dragover {
  opacity: 0.6;
}

.integration_integration_drop-file-input__label {
  text-align: center;
  padding: 10px;
}

.integration_drop-file-input__label img {
  width: 50px;
}
.integration_drop-file-input__label_small img {
  width: 40px;
}

.integration_drop-file-input_subtitle {
  opacity: 0.4;
  margin-top: 0;
  margin-bottom: 3rem;
}

.integration_drop-input_select {
  color: #6ac5ab;
  display: inline;
  padding: 7px 10px;
  border: 2px solid #6ac5ab;
  border-radius: 5px;
  font-family: inherit;
  font-size: 1rem;
}
