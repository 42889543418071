.stroke {
  display: block;
  position: relative;
  animation: animate 1.2s linear infinite;
}

@keyframes animate {
  50% {
    height: 20%;
  }
  100% {
    height: 100%;
  }
}

.stroke:nth-child(1) {
  animation-delay: 0s;
}
.stroke:nth-child(2) {
  animation-delay: 0.3s;
}
.stroke:nth-child(3) {
  animation-delay: 0.6s;
}
.stroke:nth-child(4) {
  animation-delay: 0.9s;
}
.stroke:nth-child(5) {
  animation-delay: 0.6s;
}
.stroke:nth-child(6) {
  animation-delay: 0.3s;
}
.stroke:nth-child(7) {
  animation-delay: 0s;
}
.stroke:nth-child(8) {
  animation-delay: 0.3s;
}
.stroke:nth-child(9) {
  animation-delay: 0.6s;
}
.stroke:nth-child(10) {
  animation-delay: 0.9s;
}
.stroke:nth-child(11) {
  animation-delay: 0.6s;
}
.stroke:nth-child(12) {
  animation-delay: 0.3s;
}
.stroke:nth-child(13) {
  animation-delay: 0s;
}
.stroke:nth-child(14) {
  animation-delay: 0.3s;
}
.stroke:nth-child(15) {
  animation-delay: 0.6s;
}
.stroke:nth-child(16) {
  animation-delay: 0.9s;
}
.stroke:nth-child(17) {
  animation-delay: 0.6s;
}
.stroke:nth-child(18) {
  animation-delay: 0.3s;
}
.stroke:nth-child(19) {
  animation-delay: 0s;
}
.stroke:nth-child(20) {
  animation-delay: 0.3s;
}
.stroke:nth-child(21) {
  animation-delay: 0.6s;
}
