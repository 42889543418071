.channelSelectBoxContainer {
  display: grid;
  grid-template-columns: repeat(4, auto);
  column-gap: 0.8em;
  place-items: center;
  border: 0.5px solid #e6e6e6;
  border-radius: 10px;
  padding: 0.4em 0em 0.1em 0.6em;
  margin-left: 10px;
}

.checkbox-wrapper input[type='checkbox'] {
  -webkit-appearance: none;
  appearance: none;
  width: 1.5em;
  height: 1.5em;
  border-radius: 0.15em;
  margin-right: 0.5em;
  background: #e0e7ff;
  outline: none;
  cursor: pointer;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

input.checked {
  background-color: #007a7e;
  position: relative;
}

input.checked::before {
  position: absolute;
  left: -1px;
  top: 46%;
  height: 30%;
  width: 2px;
  background-color: #004357;
  content: '';
  transform: translateX(10px) rotate(-45deg);
  transform-origin: left bottom;
}

input.checked::after {
  position: absolute;
  left: 0px;
  bottom: 4px;
  height: 2px;
  width: 57%;
  background-color: #004357;
  content: '';
  transform: translateX(10px) rotate(-54deg);
  transform-origin: left bottom;
}

.checkbox-wrapper input[type='checkbox']:disabled {
  border-color: #c0c0c0;
  background-color: #c0c0c0;
}

.checkbox-wrapper input[type='checkbox']:disabled + span {
  color: #c0c0c0;
}

.channelSelectBoxContainer svg {
  height: 20px;
  width: 20px;
  margin-bottom: 6px;
}
