.cardContainer {
  margin: 20px auto;
  max-width: 80%;
  border: 1px solid #e6e6e6;
  max-width: 90%;
  padding: 2rem;
  background: #fff;
}

.createWorkflowWrapper {
  filter: drop-shadow(0px 3px 6px rgba(39, 45, 59, 0.2));
  width: 250px;
  height: 75px;
  padding: 1.2rem 1rem 0.8rem;
  border-radius: 10px;
  cursor: pointer;
  background: #2c62ec;
  display: flex;
  justify-content: center;
  color: #fff;
}

.strategyCreateBoxStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background-color: "#004357";
}
