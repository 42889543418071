button {
  background: transparent;
  border: transparent;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background: rgba(198, 198, 198);
}
::-webkit-scrollbar-thumb {
  background: #336979;
}

body::-webkit-scrollbar,
body::-webkit-scrollbar-track {
  display: none;
}

.react-tel-input .form-control {
  border: transparent;
  background: #ecf2f7;
  height: 45px;
  width: 100%;
}

.react-tel-input .flag-dropdown {
  background: #ecf2f7;
  border: transparent;
  border-right: 1px solid #cacaca;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-container input {
  border: transparent !important;
  outline: none !important;
  font-family: inherit !important;
  width: 95px !important;
  font-family: inherit !important;
  font-size: 1rem !important;
  z-index: 100 !important;
}

.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker__header {
  background: transparent !important;
  border: transparent !important;
  padding: 0 !important;
}

.react-datepicker__month-container {
  background-color: #fff !important;
  margin-bottom: 4px !important;
}

.react-datepicker__current-month {
  background: #6ac5ab !important;
  color: #fff !important;
  padding: 1rem !important;
}

.react-datepicker__navigation {
  top: 10px !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 50% !important;
  background-color: #6ac5ab !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 50% !important;
  background-color: #6ac5ab !important;
}

.react-datepicker__day-name {
  color: #0069aa;
}

.layoutflow {
  z-index: 0 !important;
}

.MuiTable-root {
  width: 100% !important;
  min-width: 0px !important;
}

.scrollbar {
  overflow-y: scroll;
}
.scrollbar::-webkit-scrollbar-track,
.scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.scrollbar::-webkit-scrollbar {
  width: 6px;
}
.scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: #336979;
}

/* react multi select component style overwrite */
.rmsc .dropdown-container {
  background-color: transparent !important;
}
.rmsc .panel-content {
  background-color: #b2c6cc !important;
}
.rmsc .select-item {
  color: #000 !important;
}
.rmsc .select-item:hover {
  background: #004357 !important;
  color: #fff !important;
}
.rmsc .select-item.selected {
  background: #004357 !important;
  color: #fff !important;
}

/* overwrite raect-toastify */
.Toastify__toast-theme--light {
  background: transparent;
}
.Toastify__close-button {
  display: none;
}
.Toastify__toast {
  padding: 0;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.12);
}
.Toastify__toast-body {
  padding: 0;
}
